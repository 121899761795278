.loaderWrapper{
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(255,255,255,0.5);
  z-index: 99999999999999;
}
.circleWrapper{
  
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
}
.loaderWrapper>div{
  width: 300px;
  margin: auto;
}

@media only screen and (max-width: 900px) {
  .circleWrapper{
    width: 60px;
    height: 60px;
  }
  
}
