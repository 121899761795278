
form{
  border-top: 1px solid var(--border);
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.textArea{
  height: 100px;
  padding:15px;
  box-sizing: border-box;
}
form input::placeholder,
form textarea::placeholder  { /* Internet Explorer 10-11 */
  color: var(--fontColor);
  font-weight: bold;
}
form input,
form textarea{
  width: 100%;
  margin-top: 10px;
  padding:15px;
  box-sizing: border-box;
  outline: none;
  border-radius: 5px;
  border: none;
  background-color: var(--body);
  color: var(--fontColor);;
}
.commentSection{
  padding:20px 0px;
}
.commentSection span{
  color: var(--fontColor) !important;
  font-weight: bold;
}
.commentSection>div{
  padding-bottom: 10px;
}
.error{
  color: var(--red) !important;
  text-align: left;
  width: 100%;
}
.success{
  background-color: #5ad6b5c3;
  width: 100%;
  text-align: center;
  padding:3px;
  border-radius: 3px;
  box-sizing: border-box;
  margin-bottom: 10px;
  display: flex;
  color:  var(--white) !important;

}
form button{
  color: var(--fontColor);;
  outline: none;
  border: none;
  background-color: var(--fontColor);
  color: var(--white);
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
  width: fit-content;
  border-radius: 5px;

}
.promiseCard {
  padding: 10px;
  border-radius: var(--radius);
  margin-top: 10px;
  background-color: var(--white);
  cursor: pointer;
  position: relative;
}
.promiseCard p {
  /* font-family: "XeraPro"; */
  font-size: 15px;
  line-height: 15px;
  line-height: 1.5em;
  letter-spacing: -0.32px;
  color: #00174d;
  margin-bottom: 0px;
}
.promiseCard span {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bolder;
  padding-top: 10px;
}
.active {
  background-color: var(--fontColor);
}
.active > p {
  color: var(--white);
}
.cardDetails {
  position: absolute;
  transition: 0.5s ease-in;
  top: -10px;
  /* right: -550px; */
  /* width: 500px; */
  /* padding: 10px; */
  /* background-color: var(--white); */
  right:-65%;
  width:55%;
  padding: 20px 20px;
  background: var(--white) url("../../../public/images/close.png") no-repeat top right;
  background-size: 32px 32px;
  border-radius: var(--radius);
  margin-top: 10px;
}
.cardDetails::before {
  /* content: url(""); */
  display: inline-block;
  height: 0px;
  width: 0px;
  position: relative;
  border-bottom: solid 10px transparent;

  border-top: solid 10px transparent;
  left: -15px;
  position: absolute;
  margin-right: 5px;
}

div.finished {
  border-left: 5px solid var(--aqua);
}
div.finished::before {
  border-right: solid 10px var(--aqua);
}
div.finished span {
  color: var(--aqua);
}

div.half {
  border-left: 5px solid var(--blue);
}
div.half::before {
  border-right: solid 10px var(--blue);
}
div.half span {
  color: var(--blue);
}

div.notStarted {
  border-left: 5px solid var(--red);
}
div.notStarted::before {
  border-right: solid 10px var(--red);
}
div.notStarted span {
  color: var(--red);
}

div.started {
  border-left: 5px solid var(--yellow);
}
div.started span {
  color: var(--yellow);
}
div.started::before {
  border-right: solid 10px var(--yellow);
}
p.promiseDetails {
  padding: 10px 0;
  border-bottom: 2px solid var(--body);
}
p.promiseDetails:last-child {
  border-bottom: 0;
}
label.date {
  padding: 1px 5px 2px;
  margin-right: 5px;
  font-weight: bold;
}

label.notStarted,
label.started,
label.half,
label.finished {
  text-transform: uppercase;
  padding: 1px 5px 2px 0;
  margin-right: 5px;
}
label.notStarted {
  color: var(--red);
}
label.started {
  color: var(--yellow);
}
label.half {
  color: var(--blue);
}
label.finished {
  color: var(--aqua);
}

@media only screen and (max-width: 900px) {
  .cardDetails {
    position: absolute;
    top: 97%;
    left: -13px;
    width: 105%;
    box-sizing: border-box;
    z-index: 99;
    border: 1px solid var(--border);
    background-color: var(--white);
    box-shadow: var(--border) 1.95px 1.95px 2.6px;
    z-index: 950;
    border-left: none !important;
  }

  .cardDetails::before {
    border-right: solid 10px transparent !important;
    border-left: solid 10px transparent;
    border-top: solid 10px var(--fontColor);
    top: -10px;
    left: 30%;
  }
  .cardDetails {
    padding: 20px;
  }
}
.commentsDisclaimer{font-size:11px;margin-top:5px;}