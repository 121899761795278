.search{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-shadow:var(--border) 0.95px 0.95px 2.6px;
  background-color: var(--white);
  /* border-radius: var(--radius); */

}
.search svg{
  padding-left: 10px;
  /* position: absolute; */
}
.options{
  border-radius: var(--radius);
  cursor: pointer;
}