.boxLoading {
  background-color: var(--white);
  box-shadow: var(--border) 1.95px 1.95px 2.6px;
  display: flex;
  box-sizing: border-box;
  padding: 5px;
}


.reportWrapper{
  display: flex;
  margin:20px 0px;
}
.reportWrapper ul{
  list-style: none;
  padding:0px;
  margin:0px;
  margin-right: 10px;
}
.reportWrapper ul li{
  background-color: var(--partyBg);
  padding:5px;
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 16.86px;
  letter-spacing: -0.56px;
  color: #00174d;
  font-size: 15px;
  text-align: center;
  transition: 0.5s;
  font-weight: bold;
  cursor: pointer;
  border-radius: var(--radius);
}
.reportWrapper ul li:hover{
  background-color: #00174d;
  color: var(--white);
}
.report{
  max-width: 130px;
  width: 100%;
}
.report img {
  width: 100%;
  height: 100%;
  border-radius: var(--radius);
  cursor: pointer;
  position: relative;
  height: 200px;
  width: 100%;
  box-shadow:var(--border) 1.95px 1.95px 2.6px;
  object-fit: cover;
}

.report h1 {
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
  line-height: 16.86px;
  letter-spacing: -0.56px;
  color: var(--fontColor);
  width: 100%;
  margin: 5px auto;
  white-space: wrap;
  overflow: hidden;
  /* font-family: "XeraPro";
  font-weight: bold; */
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.municipalityDropwodn{
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.municipalityDropwodn>div{
  justify-content: end;
}
.detailsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.detailsHeader::after {
  /* content: url(""); */
  display: inline-block;
  background-color: var(--border);
  width: 1px;
  height: 1px;
  width: calc(100% - 40px);
  position: absolute;
  margin: auto;
  left: 40px;
  bottom: 3px;
}
.detailsHeader h2 {
  margin: 0px;
  padding: 0px;
  background-color: var(--body);
  font-weight: 500;
  text-transform: capitalize;
  font-size: 15px;
  letter-spacing: -0.56px;
  color: #00174d;
  font-size: 15px;
  text-align: center;
  transition: 0.5s;
  font-weight: bold;
}
.active {
  background-color: #00174d !important;
  color: var(--white) !important;
}


@media only screen and (max-width: 900px) {
  .report{
    max-width: 100%;
  }
  .report div {
    height: 220px
  }
  .report img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--radius);
    cursor: pointer;
    position: relative;
    height: 200px;
    width: 100%;
    box-shadow: var(--border) 1.95px 1.95px 2.6px;
  }
  .reportWrapper{
    flex-direction: column;
    margin: 0px;
    /* height: 160px; */
  }
  .reportWrapper ul {
    display: flex;
    flex-wrap: wrap;
  }
  .reportWrapper ul li{
    margin-right: 10px;
  }
}

