header{
  border-bottom: 1px solid var(--border);
  padding: 10px 0px;
  position: fixed;
  z-index: 1000;
  width: 100%;
  background-color: var(--body);
  top: 0;
}
header>div{
  position: relative;

}
.header{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.header>div{
  display: flex;
}
.headerLogo{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: var(--main);
  width: 280px;
  font-size: 18px;
  /* font-family: "XeraPro";
  font-weight: bold; */
  font-family: "Poppins", sans-serif;
  font-weight: 520;
  font-style: normal;
  line-height: 1.5rem;
  position: absolute;
  left: -280px;
  top: -5px;
  padding: 10px 0px;

}
.headerLogo img{
  width: 90px;
  margin-right: 20px;
  padding-top: 5px;
}
.menuHeader{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0px;
}
.menuHeader ul{
  list-style: none;
  display: flex;
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.menuHeader ul a{
  padding: 10px 15px 10px 15px;
  margin-right: 10px;
  /* font-family:"XeraCompactProMedium"; */
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  line-height: 16.86px;
  letter-spacing: -0.80px;
  color: #00174d;
  font-size: 13px;
  text-align: center;
  transition: 0.5s;
  border-radius: var(--radius);
}
.menuHeader ul .active,
.menuHeader ul a:hover{
  background-color: var(--white);
  box-shadow: var(--border) 1.95px 1.95px 2.6px;
}
.customHeaderLogo{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  /* font-weight: bold; */
  background:#00174d url('../../../public/images/TransparencaBuxhetoreLogo.svg') no-repeat -95px center;
  background-size:80%;
  border-radius: var(--radius);
}
.customHeaderLogo span{
  width: 50%;
  display: flex;
  line-height: 13.1px;
  font-size: 12px;
  color:white;
  padding: 10px 0px;
}
/* MOBILE */
.headerMobileTop {
  position: fixed;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  padding: 5px 20px;
  border-bottom: 1px solid var(--border);
  background-color: var(--white);
  justify-content: space-between;
}
.headerMobileTop a {
  width: 160px;
}
.mobileHeader {
  position: fixed;
  top: 58px;
  width: 100%;
  background-color:var(--white);
  height: 100vh;
  transition: all 0.2s ease-in-out;
  -webkit-animation: movedown 0.5s linear forwards;
  -moz-animation: movedown 0.5s linear forwards;
  -ms-animation: movedown 0.5s linear forwards;
  -o-animation: movedown 0.5s linear forwards;
  animation: movedown 0.5s linear forwards;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}
.openMenu {
  opacity: 1;
  visibility: visible;
}
.mobileHeader ul {
  list-style: none;
  padding: 0px 10px;
}
.mobileHeader li {
  padding: 10px 0px;
  -webkit-animation: translate-title 2s linear;
  -webkit-animation-fill-mode: forwards;
  animation: translate-title 2s linear;
  animation-fill-mode: forwards;
  text-align: left;
  margin: 0;
  padding: 0;
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.5s ease-in-out;
  transition-delay: 0.1s;

}
.mobileHeader li:nth-child(2n) {
  transition-delay: 0.2s;
}
.mobileHeader li:nth-child(3n) {
  transition-delay: 0.3s;
}
.fadeIn {
  opacity: 1 !important;
  transform: translateX(10px) !important;
}
.mobileHeader a {
  /* font-family:"XeraCompactProMedium"; */
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  line-height: 16.86px;
  letter-spacing: -0.80px;
  color: #00174d;
  text-align: center;
  transition: 0.5s;
  border-radius: var(--radius);
  /* font-weight: bold; */
  padding:10px 0px;
  display: flex;
}
.mobileNav {
  display: flex;
  flex-direction: row;
  bottom: 150px;
  width: 100%;
}
.mobileNav a {
  font-size: 12px;
  margin: 5px;
  width: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobileNav > a {
  border: 1px solid var(--color);
  padding: 10px;
  margin-bottom: 20px;
  border-radius: var(--radius);
}
.pointer {
  cursor: pointer;
}

@media only screen and (max-width: 1300px) {
  .mapInfo {
    width: 250px;
  }
  /* .mapInfo > div {
    position: relative; 
  }*/
}