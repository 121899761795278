.realisation {
  width:100%;
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  transition: 0.4s;
}
.realisationWrapper{
  display: flex;
  flex-wrap: nowrap;
}
.realisation label {
  width: 25%;
  float: left;
  height: 8px;
}
.realisation p{
  padding:0px 0px 10px 0px;
}
.realisation span{
  /* font-weight: bold;
  font-family: "XeraProBold"; */
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}
span.emp{font-size:38px;display:block;}
.thickLabel label{
  padding: 10px;
  box-sizing: border-box;
}
.legendInfo{
  font-size: 15px;
  margin-top: 10px;
}
.legendInfo span{
  font-size: 16px;
  margin-right: 5px !important;
  display: flex;
}
.total{
  padding: 0px !important;
}