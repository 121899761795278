.grid3 {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-gap: 100px;
  width: 100%;
  min-height: 0; /* NEW */
  min-width: 0; /* NEW; needed for Firefox */
}
.grid2 {
  display: grid;
  grid-template-columns: 1fr  1fr;
  grid-gap: 20px;
  width: 100%;
  min-height: 0; /* NEW */
  min-width: 0; /* NEW; needed for Firefox */
}
.grid4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  width: 100%;
  min-height: 0; /* NEW */
  min-width: 0; /* NEW; needed for Firefox */
}
.grid5{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  width: 100%;
  min-height: 0; /* NEW */
  min-width: 0; /* NEW; needed for Firefox */
}
.grid12 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 10px;
  width: 100%;
  min-height: 0; /* NEW */
  min-width: 0; /* NEW; needed for Firefox */
}

.gridBottom {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 2px;
  grid-row-gap:12px;
}
.gridBottom div{
  padding:0px 5px;
}
.gridBottom div:first-child {
  grid-area: 1 / 1 / 2 / 2;
}
.gridBottom div:nth-child(2) {
  grid-area: 2 / 1 / 3 / 2;
}
.gridBottom div:nth-child(3) {
  grid-area: 3 / 1 / 4 / 2;
}
.gridBottom div:nth-child(4) {
  grid-area: 4 / 1 / 4 / 2;
}
/* .gridBottom div:last-child {
  grid-area: 1 / 2 / 2 / 3;
} */
@media only screen and (max-width: 1024px) {
  .grid5{
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
 

}
@media only screen and (max-width: 1000px) {
  .grid3 {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .grid4,
  .grid5{
    grid-template-columns: 1fr 1fr;
  }
  .grid2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    width: 100%;
    min-height: 0;
    min-width: 0;
    grid-auto-rows: 1fr;
  }
  .grid3 {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
}
