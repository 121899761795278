.realization{
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.mayorPop{
  width: fit-content;
  box-shadow: var(--border) 1.95px 1.95px 2.6px;
  padding:2px 15px 2px 5px; 
  border-radius: 50px;
  border: 3px solid var(--white);
  display: flex;
  background-color: var(--white); 
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.35s;
}
.mayorPopLoading{
  width: 72px;
  height: 72px;
  box-shadow: var(--border) 1.95px 1.95px 2.6px;
  padding: 0px;
  border-radius: 50px;
  border: 3px solid var(--white);
  display: flex;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  font-size: 13px;
  text-align: center;
  align-items: center;
}
.mayorImage{
  width: 70px;
  height: 70px;
  border-radius: 100%;
}
.mayorImage img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
  image-rendering: -webkit-optimize-contrast;
  will-change: transform;
  /* background:url("../../../public/images/simple-loader.gif") no-repeat center center; background-size:32px 32px; */
}
.party{
  margin-left: 20px;
}
.mayorDetails{
  font-size: 13px;
  padding-left: 5px;
}
.mayorDetails span{
  font-size:12px;
}
.mainText{
  font-size: 18px !important;
}

.mayorPopMobile{
  background-color: white;
  padding:20px 10px;
  /* width: 60%; */
  width: 200px;
  border-radius: var(--radius);
}
.mayorPopMobile .mayorImage{
  margin:auto;
}
.mayorPopMobile h3{
  padding:10px 0px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 900px) {
  .mayorPop{
   flex-direction: column;
   padding:2px;
   width: 60px;
   position: relative;
  }
  .mayorImage{
    width: 60px;
    height: 60px;
    border-radius: 100%;
  }
  .mayorDetails {
    display: none;
  }
  .mayorPopLoading{
    width: 60px;
    height: 60px;
  }
  
}



