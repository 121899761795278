.homepageFilter{
  background-color: var(--white);
  box-shadow: var(--border) 1.95px 1.95px 2.6px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: absolute;
  width: 300px;
  z-index: 999 !important;
  left: -50px;
}
.filterWrapper{
  width: 100%;
  border-bottom: 2px solid var(--body);
}
.filterWrapperPadding{
  width: 100%;
  padding:15px;
  box-sizing: border-box;
  overflow-wrap: break-word;
  border-bottom: 2px solid var(--body);
}
.filterWrapperPadding span{
  font-size: 14px;
  color: var(--fontColor);
  /* font-family: "XeraPro"; */
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}
.municipality{
  display: flex;
  margin-top: 5px;
  flex-wrap: wrap;
}
.municipality div{
  width: fit-content;
  padding:3px 5px;
  cursor: pointer;
  overflow: hidden;
  transition: 0.5s;
}
.municipality div:hover,
.active{
  color: var(--partyBg);
  background-color: var(--fontColor);
}
.filterToggle{
  width: 60px;
  background-color: var(--partyBg);
  box-shadow: 0px -2px 0px 0px var(--border);
  border-radius: 20px;
  padding:3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-right: 10px;

}
.filterToggle span{
  width: 12px;
  height: 12px;
  display: flex;
  border-radius: 100%;
  padding:5px;
  cursor: pointer;
  transition: 0.5s;
}
.filterToggle .active,
.filterToggle span:hover
{
  background-color: var(--white);
  display: flex;
  border-radius: 100%;
  padding:5px;
  cursor: pointer;
}
@media only screen and (max-width: 900px) {
  .homepageFilter{
    width: 260px;
    left: 0;
  }
}


