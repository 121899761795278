.iconWrapper{
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  /* box-shadow: 0px -2px 0px 0px var(--border); */
  box-shadow: var(--border) 1.95px 1.95px 2.6px;
  padding: 10px;
  background-color: var(--white);
  margin-right: 10px;
  cursor: pointer;
}


