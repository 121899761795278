.promiseField{
  width: 100%;
  height: 200px;
  background-image: linear-gradient(to right bottom, #d8e0ee, #cfd9eb, #c7d2e7, #bfcae4, #b7c3e0);
  border-radius: var(--radius);
  box-shadow: var(--border) 1.95px 1.95px 2.6px;
  border-bottom: 2px solid var(--fontGray);
  padding:10px;
  box-sizing: border-box;
  position: relative;
  background-size: cover;
  position: relative
}
.promiseField img{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 80%;
  object-fit: contain;
  opacity: 0.3;
}
.promiseField h3{
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: -0.9px;
}
.promiseField p{
  font-size: 20px;
}
.fieldRealisation{
  position: absolute;
  bottom: 10px;
  width: 90%;
  box-sizing: border-box;
}

/* 
@media only screen and (max-width: 900px) {
  .promiseField{
    height: 160px;
  }
}
 */
