.dropdown{
  position: relative;
  width: fit-content;
  width: 100%;
  display: flex !important;
  flex-wrap: nowrap;
}
.dropdown p {
  font-size: 14px;
  cursor: pointer;
  color: var(--fontGray);
  /* font-family: "XeraPro"; */
}
.fullWidth .dropdownBody{
  width: 100%;
  top: 30px;
}
.dropdownBody{
  opacity: 0;
  visibility: hidden;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 150px;
  border-radius: var(--radius);
  position: absolute;
  box-shadow: 0px 0px 0px -3px var(--border);
  top: 20px;
  font-size: 13px;
  transition: 0.1s;
  background-color: var(--white);
  transition: visibility 0s linear 0.3s, opacity 0.3s linear;
  z-index: 9999;
  max-height: 250px;
  overflow-y: scroll;
}
.dropdownBody li{
  padding:10px;
  border-bottom: 1px solid var(--border);
  cursor: pointer;


}
.dropdownBody li:first-child{
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
}
.dropdownBody li:hover{
  background-color: var(--partyBg);
  transition: 0.2s;

}
.dropdown:hover .dropdownBody{
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
  animation: slide-up 0.2s ease-in-out;
}
.dropdownHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--partyBg);
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 20px;
  color: var(--fontColor) !important;
  cursor: pointer;
  /* font-family: "XeraCompactProBold"; */
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.dropdownHeader p{
  color: var(--fontColor) !important;
  padding-left: 10px;

}
.dropdownHeader div{
  display: flex;
  align-items: center;
}
@keyframes slide-up {
  from {
    transform: translateY(20px);
  }
  to {
    transform: translateY(5px);
  }
}