.reverse h1{
  /* flex-direction: column-reverse; */
  padding: 5px 0px !important;
  font-size: 16px !important;
  line-height: 18px !important;
  /* font-family: "XeraPro" !important;
  font-weight: 400 !important; */
  font-family: "Poppins", sans-serif !important;
  font-weight: 300 !important;
  font-style: normal !important;
  letter-spacing: -0.9px !important;
  width: 70% !important;
} 
.reverse p{
  font-size: 50px !important;
  line-height: 25px !important;
  /* font-family: "XeraProBold" !important;
  font-weight: bold;
  font-weight: bolder !important; */
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-weight: 700  !important;
  font-style: normal;
}
@media only screen and (max-width: 900px) {
  .reverse h1{
    width: 100%;
  }
  .reverse p{
    font-size: 45px;

  }
}