.quickAction{
  width: 100%;
  height: fit-content;
  position: sticky;
  top: 100px;
}


.detailsWrapper {
  padding: 20px 0px;
}
.detailsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.detailsHeader::after {
  /* content: url(""); */
  display: inline-block;
  background-color: var(--border);
  width: 1px;
  height: 2px;
  width: 100%;
  position: absolute;
}
.detailsHeader h2 {
  margin: 0px;
  padding: 0px;
  background-color: var(--body);
  font-weight: 500;
  text-transform: capitalize;
  font-size: 22px;
  padding-right: 15px;
  z-index: 2;
}

.detailsHeader span {
  text-transform: uppercase;
  font-size: 12px;
  color: #00174d;
  font-weight: bold;
  background-color: var(--body);
  z-index: 2;
  padding-left: 15px;


}
.catAndPromiseWrapper {
  margin-top: 15px;
}

.quickAction {
  position: absolute;
  left: 0;
  width: 400px;
  background-color: var(--partyBg);
  height: fit-content;
  padding: 10px 20px;
  border-radius: var(--radius);
}
.quickAction::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-right: 10px solid var(--partyBg);
  position: absolute;
  top: 30px;
  left: -10px;
}
.quickAction ul {
  padding: 0px;
  font-size: 14px;
  list-style: none;
}
.quickAction ul a {
  display: flex;
  align-items: center;
  color: var(--fontColor);
  padding-bottom: 5px;
}
.quickAction ul li:last-child a {
  padding-bottom: 0px;
}
.quickAction ul a svg {
  margin-right: 5px;
}

.zeroPromise {
  padding:10px;
  background-color: var(--white);
  border-radius: var(--radius);
  margin-bottom: 10px;
}

@media only screen and (max-width: 900px) {
  .quickAction{
    display: none;
  }
  .detailsHeader::after {
      width: 100%;
      left: 0;
      /* left: 40%; */
  }
}

