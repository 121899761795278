.card {
  background-color: var(--white);
  box-shadow: var(--border) 1.95px 1.95px 2.6px;
  padding:10px;
  display: flex;
  border-radius: var(--radius);
}
.imageWrapper{
  height: 100px;
  width: 80px;
  overflow: hidden;
  display: flex;
}
.imageWrapper img{
  width: 100%;
  height: 100%;
  object-fit:cover;
}
.body{
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
}
.card span{
  font-weight: bold;
  white-space: nowrap;
  font-size: 13px;
  padding: 2px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: center;
}

@media only screen and (max-width: 900px) {
  .card{
    flex-direction: column;
    margin: 0px;
    max-height: 90%;
    min-height: 90%;
    /* height: 160px; */
  }
  .card p{
    font-size: 16px;
  }
  .imageWrapper{
    width: 100%;
    height: 150px;
  }
  .body{
    width: 100%;
    padding: 5px 0px 0px 0px;
  }
}
