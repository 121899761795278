
.mayorCard{
  position: sticky;
  width:100%;
  z-index: 988;
  background-color: var(--body);
  top:80px;
  padding: 20px 0px 10px 0px; 
}
.imageWrapper {
  width: 150px;
  margin-right: 20px;
  transition: 0.4s;
}
.mayorScroll{
  border-bottom: 1px solid var(--border);
}
.mayorScroll .imageWrapper{
  width: 80px !important;
  height: 80px;
}
.imageWrapper img,
.imageWrapperScroll  {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--radius);
  transition: 0.4s;

  image-rendering: -webkit-optimize-contrast;
  will-change: transform;
}
.otherWrapper{
  display: flex;
  flex-wrap: wrap;
  transition: 0.4s;
}

.otherImages{
  width: 45px;
  margin-right: 10px;
  opacity: 0.45;
  transition: 0.4s;
  transition: 0.4s;

}
.mayorScroll .otherWrapper{
  display: none;
}

.otherImages:hover,
.otherActive{
  opacity: 1;
}
.otherImages img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--radius);
  
  image-rendering: -webkit-optimize-contrast;
  will-change: transform;
}
.mayorInfo {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  padding-left: 10px;
  width: 100%;
}


.mayorInfo h1{
  font-size:40px;
  /* font-family: "XeraProBold"; */
  letter-spacing: -1.5px;
  transition: 0.4s;
  line-height:1em;
}

.mayorInfo  h3{
  font-weight: 300;
  font-size: 20px;
  letter-spacing: -0.5px;
}
.mandate {
  display: flex;
  align-items: center;
}
.mandate ul {
  padding: 0px;
  margin: 0px;
  display: flex;
  list-style: none;
  width: 100%;
  padding-left: 10px;
}
.mandate ul li {
  margin-left: 10px;
  display: flex;
}
.mandate ul li a {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  font-weight: bold;
}
.active,
.mandate ul li a:hover{
  background-color: var(--white);
  box-shadow: var(--border) 1.95px 1.95px 2.6px;
}
.mayorScroll p{
  font-size: 12px;
}

.mayorScroll h3{
  font-size: 15px;
}
.mayorScroll h1{
  font-size:20px;
}
.fbShareButton{ margin:5px; }
@media only screen and (max-width: 900px) {
  .mayorCard{
    top: 55px;
    padding: 0px 0px 10px 0px
  }
  .mayorScroll{
    padding: 20px 0px 10px 0px
  }
  .fbShareButton{ margin-top:50px; margin-right:-5px; }
}
span.lowerCase {text-transform:lowercase;}


