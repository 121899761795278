.list {
  list-style: none;
  width: 100%;
  height: fit-content;
  padding: 0px;
  margin: 0px;
  border-bottom: 1px solid var(--border);
  /* border-top: 1px solid var(--border); */
  position: sticky;
  top: 100px;
}
.list li {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  border-bottom: 1px solid var(--border);
  font-size: 14px;
  line-height: 15px;
  letter-spacing: -0.32px;
  color: #00174D;
  margin-bottom: 0px;
  padding: 8px 0px;
  cursor: pointer;
  transition: 0.5s;
}
.list li:hover{
  font-weight: bold;
  color: var(--blue);
}
.list li:last-child {
  border: none;
}
.mobList{
  position: relative;
}
.mobList>div{
  position: fixed;
  right: 0;
  top: 80px;
  z-index: 999;
}
.mobList>div>div{
  border: 1px solid var(--border);
  
}
.mobList .list{
  position: fixed;
  border-radius: var(--radius);
  padding: 10px;
  background-color: var(--white);
  width: 50%;
  z-index: 998;
  right: 10px;
  /* top: 50px; */
  bottom: 155px;
}

.mobList .list li{
  border-bottom: 0px;
}

@media only screen and (max-width: 900px) {
  
}

.formattedLastUpdate {
  text-align: center;
  /* font-size: 12px; */
  color:var(--fontColor);
  padding:5px 0 3px;
  display: block;
  border-bottom: 1px solid var(--border);
}