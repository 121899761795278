
.mapContainer{
  position: relative;
}
.mapRightInfo{
  position: absolute;
  top: 0px;
  right: 0;
  width: 160px;
  background-color: var(--white);
  box-shadow: 0px -2px 0px 0px var(--border);
  padding:10px;
  display: flex;
  align-items: top;
  border-radius: var(--radius);
}
.mapRightInfo p{
  padding-left: 10px;
  font-size: 11px;
  line-height: 13px;
}
.mapRightInfo div{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.mapRightInfo span{
  /* font-family: "XeraProBold"; */
  display: flex;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.mapWrapper {
  width: 70%;
  position: relative;
  margin: auto;
  background-image: url("../../../public/images/background.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50% 7px;
  /* background-position-y:bottom; */
}
g.municipality {
  pointer-events: bounding-box;
}
.mapWrapper svg g{
  cursor: pointer;
  /* position: absolute; */
  /* z-index: -1; */
  transition: 0.5s;
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: url(#SVGID_37_);
  /* font-family: "XeraCompactProRegular"; */
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.mapWrapper svg g:hover{
  transform: translateY(3px);
  box-shadow: 0 20px 80px #0000001a, 0 5px 20px rgb(0 0 0 / 0%);
}
.foreignObject{
  fill-rule: evenodd;
  clip-rule: evenodd;
  width: 250px;
  height: 100px;
  z-index: 9999;
}
.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #97a1b4;
  /* font-family: "XeraCompactProRegular"; */
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.st2 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: url(#SVGID_1_);
  font-size: 13px;
  /* font-family: "XeraCompactProRegular"; */
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.st3 {
  enable-background: new;
  /* font-family: "XeraCompactProRegular"; */
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.st4 {
  fill: #19315a;
}
.st5 {
  /* font-family: "XeraCompactProRegular";
  font-size: 17px;
  font-weight: 500; */
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 5px;
  letter-spacing:-.75px;
  color: var(--font);

}
.mayorContainerMobile{
  position: absolute;
  background-color: rgba(255, 255,255,0.3);
  top: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* IMPORTED FROM Adobe Illustrator auto generated SVG */
@media only screen and (max-width: 1040px) {
  .mapWrapper{
    width: 70%;
  }
  .mapWrapper{
    margin-top: 70px;
  }
  .mapRightInfo{
    position: absolute;
    right: 0;
    padding: 7px;
    width: 125px;
    text-align: right;
    bottom: -7px;
    top: auto;
    background-color: transparent;
    box-shadow: none;
    display: flex;
  }
  .mapRightInfo span{
    font-size: 13px;
  }
  .mapRightInfo svg{
    display: none;
  }
  .mapRightInfo p{
    font-size: 11px;
  }
}

@media only screen and (max-width: 600px) {
  .mapWrapper{
    width: 100%;
  }

}
