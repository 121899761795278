#map {
  width: 100%;
  display: flex;
  border: 1px solid red;
}
.mapInfo {
  position: relative;
  width: 210px;
}

.mapInfo > div {
  position: absolute;
  z-index: 2;
  display: flex;
  /* left: -50px; */
}

.mapInfo h1 {
  font-size: 25px;
  line-height: 25px;
  /* font-family: "XeraProBold";
  font-weight: bold;
  font-weight: bolder; */
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.mapInfo p {
  padding: 5px 0px;
  font-size: 14px;
  line-height: 18px;
  /* font-family: "XeraPro"; */
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.card>div {
  background-color: var(--white);
  box-shadow: var(--border) 1.95px 1.95px 2.6px;
  padding:10px;
  display: flex;
  border-radius: var(--radius);
}
.card span{
  font-weight: bold;
  white-space: nowrap;
  font-size: 13px;
  padding: 2px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: center;
}
.projectDesc{
  padding:20px 0px;
}
.projectDesc h1,
.projectDesc p{
  padding-bottom: 20px;
}
.spaceBetween{
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 1040px) {
  .mapInfo {
    width: 250px;
  }
  /* .mapInfo > div {
    position: relative;
  } */
}

@media only screen and (max-width: 900px) {
  .card{
    flex-direction: column;
    margin: 0px;
    max-height: 90%;
    min-height: 90%;
    /* height: 160px; */
  }
  .card>div{
    flex-direction: column;
  }
  .imageWrapper{
    height: 145px;
  }
  
  .mapInfo {
    width: 100%;
    padding-bottom: 20px;
  }
  .mapInfo h1 {
    font-size: 22px;
  }
  .mapInfo p {
    font-size: 13px;
    line-height: 16px;
  }
}
